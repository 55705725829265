<!-- 电站管理--电站管理 -->

<template>
	<div class="power-manage main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:apiName="PowerStations.getPowerStationLists" :filters="filters" :columns="tableColumns"
				@onEditBtn="onEditBtn" @onDetailBtn="onDetailBtn">
			</common-table>
		</div>

		<!-- 电站详情 -->
		<PowerDetails ref="refPowerDetails"></PowerDetails>

		<w-dialog ref="editDialog" class="add-dialog" title="编辑" width="50%" btnWidth="140px" top="20vh"
			confirmText="确认保存" @wConfirm="addConfirm">
			<div>
				<div class="msg-title">支付配置</div>
				<el-select clearable filterable remote reserve-keyword v-model="mchPayConfigKeywords"
					placeholder="请输入商户号" remote-show-suffix :remote-method="searchMchPayConfigList"
					@change="changeMchPay">
					<el-option v-for="(item,index) in mchPayList" :key="index"
						:label="item.mp_wx_mch_id+'-'+item.mp_mch_name" :value="item.mp_id" />
				</el-select>
				<common-table ref="refUpdateStationTable" tableHeight="250px" :ispaging="false"
					:columns="paymentConfigTableColumns" @statusChange="statusChange"	@untieMerchant="untieMerchant" :tableData="updateTableData">
					<template #pd_percent="{row}">
						<input type="text" class="update-input" v-model="row.pd_percent" />%
					</template>
				</common-table>
				<div class="msg-title">基本信息</div>
				<div>
					<el-row :gutter="20">
						<el-col :span="12">
							<div class="s-m-b">车场编号</div>
							<el-input placeholder="请输入车场编号" v-model="s_hk_park_code" clearable></el-input>
						</el-col>

						<el-col :span="12">
							<div class="s-m-b">电站照片</div>
							<img-upload :limit="10" :fileList="fileList" @uploadFile="uploadFile" @deleteFile="deleteFile"
								suggestText="">
							</img-upload>
						</el-col>
					</el-row>
				</div>
			</div>
		</w-dialog>
	</div>
</template>
<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
		nextTick
	} from "vue";
	import {
		PowerStations
	} from "@/plugins/api.js";
	import {
		unique,
		objToStirng
	} from "@/utils/common.js";
	import {
		ElMessage
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import PowerDetails from "../components/PowerDetails.vue";
	import imgUpload from "@/components/img-upload/img-upload.vue";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const qiniuData = computed(() => store.state.Config.qiniuData);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		}, {
			deep: true,
			immediate: true,
		}
	);


	/*********支付配置start********/

	const paymentConfigTableColumns = ref([{
		prop: "mp_wx_mch_id",
		label: "商户号",
		color: "--text-color",
		showTooltip: true,
	}, {
		prop: "mp_mch_name",
		label: "商户名称",
		color: "--text-color",
		showTooltip: true,
		minWidth: 150,
	}, {
		prop: "pd_percent",
		label: "分成占比",
		color: "--text-color",
		type: 'customRender'
	}, {
		prop: "mp_remark",
		label: "备注",
		color: "--text-color",
		showTooltip: true,
	}, {
		prop: "mp_tip",
		label: "是否默认",
		color: "--text-color",
		type: 'switch',
		token:'n_LHBANDmm2rnIIDMV7UaXBq1jFTVB'
	}, {
		type: "operation",
		label: "操作",
		minWidth: 100,
		prop: "",
		bottons: [{
			name: "解绑",
			action: "untieMerchant",
			token:'n_LHBANDmm2rnIIDMV7UaXBq1jFTVB'
		}],
	}, ])


	const searchMchPayConfigList = (e) => {
		PowerStations.stationMchPayConfigList({
			keywords: e,
			limit: 10000
		}).then(res => {
			if (res.Code == 200) {
				mchPayList.value = res.Data.list
			}
		})
	}
	//解绑商户
	const untieMerchant = (e,i) =>{
		updateTableData.value.splice(i,1)
	}
	const statusChange = (e) =>{
		updateTableData.value.map(res =>{
			res.mp_tip = 2
		})
		if(e.mp_tip == 1){
			e.mp_tip = 2
		}else if(e.mp_tip == 2){
			e.mp_tip = 1
		}
		nextTick(() => {
			refUpdateStationTable.value.tableLoad()
		})
	}
	
	const refUpdateStationTable = ref(null)
	const mchPayConfigKeywords = ref('')
	const mchPayList = ref([])
	const changeMchPay = (e) => {
		if (mchPayList.value) {
			const row = mchPayList.value.filter(res => {
				if (res.mp_id == e) {
					return res
				}
			})
			if (row && row[0]) {
				updateTableData.value.push(row[0])
			}
			updateTableData.value = unique(updateTableData.value)
		}
		nextTick(() => {
			refUpdateStationTable.value.tableLoad()
		})
	}
	const updateTableData = ref([])
	/*********支付配置end******/



	/** 表格对象 */
	const roleTable = ref(null);
	/** 筛选条件列表 */
	const filters = ref([{
		name: "keywords",
		filterType: "search",
		value: "",
		placeholder: "请输入电站名称搜索",
	}, ]);

	/** 表格配置数据 */
	const tableColumns = ref([{
			prop: "s_name",
			label: "电站名称",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "s_no",
			label: "电站编号",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "city_name",
			label: "区域",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "equipment_count",
			label: "充电桩总数",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "equipment_count_fast",
			label: "快充数量",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "equipment_count_slow",
			label: "慢充数量",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "s_busine_hours",
			label: "营业时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "mi_name",
			label: "上线明细",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "s_hk_park_code",
			label: "关联停车场",
			color: "--text-color",
			showTooltip: true,
			minWidth: 100
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 200,
			bottons: [{
					name: "详情",
					action: "onDetailBtn",
					token: "n_ecVcIhACW1r4dxjUggRXQhHBUdvG",
				},
				{
					name: "编辑",
					action: "onEditBtn",
					token: "n_LHBANDmm2rnIIDMV7UaXBq1jFTVB",
				},
			],
		},
	]);

	// 详情组件对象
	const refPowerDetails = ref(null);
	// 编辑对话框对象
	const editDialog = ref(null);
	// 上传数据
	const fileList = ref([]);
	const currentRow = ref('');
	const s_hk_park_code = ref('');  // 车场编号
	/**
	 * 
	 * 查看详情按钮
	 * 
	 * */
	const onDetailBtn = (row) => {
		refPowerDetails.value.openDialog(row);
	}
	/**
	 * 
	 * 编辑按钮
	 * 
	 * */
	const onEditBtn = (row) => {
		currentRow.value = row;
		fileList.value = [];
		updateTableData.value = [];
		mchPayConfigKeywords.value = '';
		mchPayList.value = [];
		store.dispatch("getQiniuData");
		PowerStations.stationGetEditInfo({
			s_id: row.s_id
		}).then(res => {
			if (res.Code === 200) {
				if(res.Data.pay_list.length != 0){
					updateTableData.value = res.Data.pay_list;
				}
				if (res.Data.pic_list.station_pictures.length > 0) {
					res.Data.pic_list.station_pictures.forEach(item => {
						fileList.value.push({
							file_url: item,
						});
					})
					fileList.value = res.Data.pic_list.station_pictures;
				}
				s_hk_park_code.value = res.Data.pic_list.s_hk_park_code;
				editDialog.value.show();
				
				if(res.Data.pay_list.length != 0){
					nextTick(() => {
						refUpdateStationTable.value.tableLoad()
					})
				}
				
			} else {
				ElMessage.error(res.Message);
			}
		})

	};
	/**
	 * 
	 * 编辑确认保存按钮
	 * 
	 * */
	const addConfirm = () => {
		let arr = [];
		if (fileList.value.length > 0) {
			fileList.value.forEach((item) => {
				arr = arr.concat(item.key);
			});
		}
		
		if(updateTableData.value.length > 0) {
			let pd_all =  updateTableData.value.reduce((prev, cur) =>{
				return prev + parseInt(cur.pd_percent)
			},0)
			if(!pd_all){
				ElMessage.error("请填写完整分成占比");
				return
			}
			if(pd_all != 100){
				ElMessage.error("分成占必须100%");
				return
			}
		}
		let params = {
			s_hk_park_code: s_hk_park_code.value,
			s_id: currentRow.value.s_id,
			pics: arr.join(","),
			pays: updateTableData.value
		};
		
		editDialog.value.isLoading = true;
		PowerStations.editStation(params).then((res) => {
			editDialog.value.isLoading = false;
			if (res.Code === 200) {
				editDialog.value.close();
				roleTable.value.tableLoad();
				ElMessage.success("编辑成功");
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 文件上传
	 * 
	 * */
	const uploadFile = (data) => {
		fileList.value.push({
			file_url: qiniuData.value.http_domain + data.key,
			key: data.key
		});
	};
	/**
	 * 
	 * 文件删除
	 * 
	 * */
	const deleteFile = (data) => {
		let index1 = fileList.value.findIndex((item) => item.key === data.key);
		fileList.value.splice(index1, 1);
		PowerStations.delStationPicture({
			s_id: currentRow.value.s_id,
			pic: data.key
		}).then((res) => {
			if (res.Code === 200) {
				ElMessage.success("删除成功");
			} else {
				ElMessage.error(res.Message);
			}
		});
	};

	onMounted(() => {
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss" scoped>
	.power-manage {
		font-family: "Source Han Sans CN";

		.content {
			padding: 20px;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 10px 15px 15px 30px;
			}
		}

		.update-input {
			border: none;
			width: 40px;
			border-bottom: 1px solid #eee;
		}
	}

	.msg-title {
		font-weight: bold;
		font-size: 15px;
		color: var(--text-color);
		padding: 6px 0;
	}
</style>