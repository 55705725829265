<!-- 电站详情 -->

<template>
    <div class="power-detail-dialog">
        <w-dialog ref="dialogRef" title="电站详情" width="60%" top="6vh" :hideFooter="true">
            <div>
                <div class="msg-title">基本信息</div>
                <el-row>
                    <el-col :span="8" class="col">
                        <div class="bg-title b-t-l-r">电站名称</div>
                        <div class="contents border-bottom">{{ infoDatas.s_name }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">管理区域</div>
                        <div class="contents border-bottom">/</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">电站编号</div>
                        <div class="contents border-bottom">{{ infoDatas.s_no }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title b-b-l-r">位置</div>
                        <div class="contents">{{ infoDatas.s_address }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">电站所属环境</div>
                        <div class="contents">{{ infoDatas.station_contruction }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">详细地址</div>
                        <div class="contents">{{ infoDatas.s_address }}</div>
                    </el-col>
                </el-row>
            </div>
            <div>
                <div class="msg-title">运营信息</div>
                <el-row>
                    <el-col :span="8" class="col">
                        <div class="bg-title b-t-l-r">对外开放</div>
                        <div class="contents border-bottom">/</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">电站类型</div>
                        <div class="contents border-bottom">{{ infoDatas.station_type }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">营业时间</div>
                        <div class="contents border-bottom">{{ infoDatas.s_busine_hours}}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title b-b-l-r">服务电话</div>
                        <div class="contents">{{infoDatas.s_station_tel}}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">快充数量</div>
                        <div class="contents">{{infoDatas.equipment_count_fast}}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="bg-title">慢充数量</div>
                        <div class="contents">{{infoDatas.equipment_count_slow}}</div>
                    </el-col>
                </el-row>
            </div>
            <div>
                <div class="msg-title">收费规则</div>
                <common-table ref="areaTable" tableHeight="250px" :ischeck="false" :ispaging="false"
                    :tableData="infoDatas.policy_list" :columns="areaTableColumns">
                </common-table>
            </div>
            <div v-if="fileList.length > 0">
                <div class="msg-title">电站照片</div>
                <img-upload uploadTitle="" suggestText="" :fileList="fileList" :isShowUpload="false"
                    :noReplaceImg="false"></img-upload>
            </div>
            <!-- <div>
                <div class="msg-title">营销活动</div>
                <div class="activity">营销活动</div>
            </div> -->
        </w-dialog>
    </div>
</template>

<script>
    import { ref, computed, nextTick } from "vue";
    import { PowerStations, } from "@/plugins/api.js";
    import imgUpload from "@/components/img-upload/img-upload.vue";
    import { ElMessage } from "element-plus";

    export default {
        emits: ["submit"],
        components: {
            imgUpload,
        },
        setup() {
            // 弹框对象
            const dialogRef = ref(null);
            const infoDatas = ref({});  // 详情数据
            // 图片数据
            const fileList = ref([]);
            // 表格对象
            const areaTable = ref(null);
            // 表格配置
            const areaTableColumns = ref([
                {
                    prop: "sp_start_time_dec",
                    prop2: "sp_end_time_dec",
                    type: "connect",
                    connectText: "-",
                    label: "时间段",
                    color: "--text-color",
                    showTooltip: true,
                },
                {
                    prop: "sp_ele_price",
                    label: "电费",
                    color: "--text-color",
                    showTooltip: true,
                },
                {
                    prop: "sp_sevice_price",
                    label: "服务费",
                    color: "--text-color",
                    showTooltip: true,
                },
            ]);
            /**
             * 
             * 打开弹框
             * 
             * */
            const openDialog = (row) => {
                fileList.value = [];
                PowerStations.getPowerStationInfo({ s_id: row.s_id }).then((res) => {
                    if (res.Code === 200) {
                        dialogRef.value.show();
                        infoDatas.value = res.Data;
                        // 处理图片
                        if (res.Data.station_pictures.length > 0) {
                            fileList.value = res.Data.station_pictures;
                        }
                        // 收费规则
                        nextTick(() => {
                            areaTable.value.tableLoad();
                        });
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            };

            return {
                openDialog,
                areaTable,
                areaTableColumns,
                PowerStations,
                fileList,
                dialogRef,
                infoDatas,
            };
        },
    };
</script>

<style lang="scss">
    .msg-title {
        font-weight: bold;
        font-size: 15px;
        color: var(--text-color);
        padding: 6px 0;
    }

    .el-row {
        border-radius: 5px;
        border: 1px solid var(--border-color);
        margin-bottom: 10px;
    }

    .col {
        display: flex;
    }

    .bg-title {
        background-color: var(--search-bg-color);
        padding: 10px;
        min-width: 120px;
        color: var(--text-third-color);
        font-weight: bold;
    }

    .contents {
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        color: var(--text-color);
    }

    .b-t-l-r {
        border-top-left-radius: 5px;
    }

    .b-b-l-r {
        border-bottom-left-radius: 5px;
    }

    .border-bottom {
        border-bottom: 1px solid var(--border-color);
    }

    .activity {
        background-color: var(--search-uncheck-bg-color);
        border-radius: 5px;
        padding: 10px 16px;
        min-height: 100px;
    }
</style>